import { AnimatePresence, Variants, m } from 'framer-motion'
import { useCheckout } from 'lib-shopify'
import Link from 'next/link'
import { useRouter } from 'next/router'

const CartButton = () => {
  const { pathname } = useRouter()
  const { cart } = useCheckout()

  return (
    <>
      {pathname.includes('shop') ? (
        <button className="flex items-baseline" onClick={cart.toggle}>
          Cart
        </button>
      ) : (
        <Link href="/shop" scroll={false}>
          Shop
        </Link>
      )}
      <ProductCounter />
    </>
  )
}

const ProductCounter = () => {
  const { pathname, push } = useRouter()
  const { cart } = useCheckout()
  // const [scope, animate] = useAnimate()

  const counterValue = Number(cart.value?.lineItems.length ?? 0)

  function handleClick() {
    if (pathname === '/') return push('/shop')
    cart.toggle()
  }

  // useEffect(() => {
  //   if (!cart.value) return
  //   async function animatePulse() {
  //     await animate(
  //       scope.current,
  //       { backgroundColor: 'var(--color-branding)' },
  //       { delay: 0 }
  //     )
  //     await animate(
  //       scope.current,
  //       { backgroundColor: 'var(--white)' },
  //       { delay: 1 }
  //     )
  //   }

  //   animatePulse()
  // }, [cart.value])

  return (
    <m.button
      // ref={scope}
      className="ml-2 w-12 h-12 aspect-square bg-white text-black flex items-center justify-center overflow-hidden"
      onClick={handleClick}
    >
      <AnimatePresence mode="popLayout" initial={false}>
        <m.div
          key={counterValue}
          initial="initial"
          animate="animate"
          exit="exit"
          variants={cartCounterVariants}
          transition={{ type: 'ease', ease: [0.6, 0, 0, 0.9] }}
        >
          {counterValue}
        </m.div>
      </AnimatePresence>
    </m.button>
  )
}

const cartCounterVariants: Variants = {
  initial: { y: '100%' },
  animate: { y: '0%' },
  exit: { y: '-100%' },
}

export default CartButton
