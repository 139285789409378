import { m, useScroll } from 'framer-motion'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useRef, useState } from 'react'

import CartButton from './cart'
import { CollapsedLogotype, Glyph, Logotype } from './glyphs'

// import GlyphAnimationLoader from '@components/glyphAnimationLoader'
import { useLenis } from '@lib/lenisContext'
import { easeInOutExpo } from '@lib/utils'

const scale = (number, fromRange, toRange) => {
  return (
    ((number - fromRange[0]) * (toRange[1] - toRange[0])) /
      (fromRange[1] - fromRange[0]) +
    toRange[0]
  )
}

const Nav = () => {
  const { scrollY } = useScroll()
  const router = useRouter()
  const { pathname } = router

  const isHomepage = pathname === '/'

  const lenis = useLenis()

  const logoRef = useRef<HTMLDivElement>(null)
  const contractedGlyphRef = useRef<HTMLDivElement>(null)
  const targetRef = useRef<HTMLDivElement>(null)

  const [defaultCollapsed, setDefaultCollapsed] = useState(!isHomepage)
  const [hasScrolled, setHasScrolled] = useState<boolean>(defaultCollapsed)

  useEffect(() => {
    cb(/* true */)
    window.addEventListener('resize', () => cb(/* true */))

    if (defaultCollapsed) return

    scrollY.on('change', () => cb(/* false */))

    return () => {
      window.removeEventListener('resize', () => cb(/* true */))
      scrollY.destroy()
    }
  }, [scrollY, pathname, defaultCollapsed])

  useEffect(() => {
    setTimeout(() => {
      if (isHomepage) setDefaultCollapsed(false)
      else setDefaultCollapsed(true)
    }, 1000) // this 1000 was fine-tuned to be in sync with PageLoader component timing
  }, [isHomepage])

  function cb(/* immediate = false */) {
    if (!logoRef.current || !contractedGlyphRef.current || !targetRef.current)
      return

    animateLogo(/* immediate */)

    const scrolled = scrollY.get()
    if (scrolled > logoRef.current.offsetHeight) {
      setHasScrolled(true)
    } else {
      setHasScrolled(false)
    }
  }

  function animateLogo(/* immediate = false */) {
    const scrolled = scrollY.get()

    const contrHeight =
      contractedGlyphRef.current.getBoundingClientRect().height
    const expHeight = logoRef.current.getBoundingClientRect().height

    const initialScale = defaultCollapsed ? 1 : 1 / (contrHeight / expHeight)
    const initialShift = defaultCollapsed ? 0 : (-1 / expHeight) * 80
    const translateXAmountStart =
      window.innerWidth < 768 ? (defaultCollapsed ? 0.25 : 0) : 0
    const translateXAmountEnd = window.innerWidth < 768 ? 0.25 : 0

    const scrollDomain = [0, expHeight]
    const scaleRange = [initialScale, 1]
    const translateYRange = [initialShift, 0]
    const translateXRange = [translateXAmountStart, translateXAmountEnd]

    const outScale = Math.max(
      1,
      Math.min(initialScale, scale(scrolled, scrollDomain, scaleRange))
    )
    const outY = Math.min(
      0,
      Math.max(initialShift, scale(scrolled, scrollDomain, translateYRange))
    )
    const outX = Math.min(
      translateXAmountEnd,
      Math.max(initialShift, scale(scrolled, scrollDomain, translateXRange))
    )

    targetRef.current.style.transform = `scale(${outScale}) translateY(${outY}rem) translateX(${outX}rem)`
    targetRef.current.style.opacity = '1'
  }

  function handleGoToTop(e) {
    if (!isHomepage) return

    e.preventDefault()

    lenis.scrollTo(0, {
      immediate: false,
      duration: 1,
      easing: easeInOutExpo,
    })

    window.history.pushState(null, document.documentElement.title, '/')
  }

  const collapsedLogoVariants = useMemo(
    () => ({
      initial: {
        y: defaultCollapsed ? '0%' : '100%',
        opacity: defaultCollapsed ? 1 : hasScrolled ? 1 : 0,
      },
      animate: {
        y: defaultCollapsed ? '0%' : hasScrolled ? '0%' : '100%',
        opacity: defaultCollapsed ? 1 : hasScrolled ? 1 : 0,
      },
    }),
    [defaultCollapsed, hasScrolled]
  )

  return (
    <>
      <div
        ref={logoRef}
        className="mx-auto relative flex justify-end bg-black text-white"
        style={{
          position: defaultCollapsed ? 'absolute' : 'relative',
          visibility: defaultCollapsed ? 'hidden' : 'visible',
        }}
      >
        <div className="w-[15vw]" />
        <div className="w-[85vw]">
          <Logotype width="auto" />
        </div>
      </div>
      <m.div
        id="main-nav"
        className="z-[998] shrink-0 bg-black text-white border-b-px border-white text-base lg:text-2xl sticky top-0 left-0 right-0 flex justify-between items-center"
      >
        <Link
          href="/"
          scroll={false}
          onClick={handleGoToTop}
          aria-label="Go to homepage"
          className=""
        >
          <m.div className="relative flex h-8 w-full">
            {/*  */}
            <m.div
              ref={contractedGlyphRef}
              className="relative h-8 w-[28px] shrink-0"
            >
              <m.div
                ref={targetRef}
                className="absolute inset-0 bottom-0 left-0 origin-bottom-left"
              >
                <Glyph />
              </m.div>
            </m.div>
            {/*  */}
            <m.div className="relative h-full w-full ml-1 overflow-hidden">
              <m.div
                className="md:w-full h-full"
                initial={collapsedLogoVariants.initial}
                animate={collapsedLogoVariants.animate}
                transition={{ ease: [0.65, 0.15, 0.25, 0.95] }}
              >
                {/* <GlyphOrLoader /> */}
                <CollapsedLogotype width="auto" height="100%" />
              </m.div>
            </m.div>
          </m.div>
        </Link>

        {/* Links */}
        <div className="flex items-baseline text-2xl">
          <Links isHomepage={isHomepage} />
          <CartButton />
        </div>
      </m.div>
    </>
  )
}

// const GlyphOrLoader = () => {
//   // const timeoutRef = useRef(null)
//   const [showLoading, setShowLoading] = useState(false)

//   useEffect(() => {
//     let id

//     function retriggerLoading() {
//       if (id) window.clearTimeout(id)

//       setShowLoading(false)

//       id = window.setTimeout(() => {
//         setShowLoading(true)
//       }, 10000)
//     }

//     retriggerLoading()
//     window.addEventListener('mousemove', retriggerLoading)
//     window.addEventListener('touchstart', retriggerLoading)
//     window.addEventListener('wheel', retriggerLoading)
//     window.addEventListener('scrollend', retriggerLoading)

//     return () => {
//       window.clearTimeout(id)
//       window.removeEventListener('mousemove', retriggerLoading)
//       window.removeEventListener('touchstart', retriggerLoading)
//       window.removeEventListener('wheel', retriggerLoading)
//       window.removeEventListener('scrollend', retriggerLoading)
//     }
//   }, [])
//   return (
//     <>
//       {showLoading ? (
//         <div className="relative w-8 h-full text-white">
//           <GlyphAnimationLoader />
//         </div>
//       ) : (
//         <CollapsedLogotype width="auto" height="100%" />
//       )}
//     </>
//   )
// }

const cn = 'leading-none mr-4 last:mr-0 nowrap'

const Links = ({ isHomepage }: { isHomepage: boolean }) => {
  const lenis = useLenis()

  function handleClick(e, anchor) {
    if (!isHomepage) {
      return
    }

    e.preventDefault()

    const anchorName = anchor.replace('#', '')
    const el = document.getElementById(anchor.replace('#', ''))
    const topOffset = el.offsetTop
    const offset = (-1 * window.innerHeight) / 2 + el.clientHeight / 2

    lenis.scrollTo(topOffset, {
      duration: 1,
      offset,
      easing: easeInOutExpo,
      immediate: false,
    })

    window.history.pushState(
      { id: anchorName },
      document.documentElement.title,
      `/${anchor}`
    )
  }

  return (
    <>
      <Link
        href="/#about"
        className={cn}
        scroll={false}
        onClick={e => handleClick(e, '#about')}
      >
        About
      </Link>
      {/* <Link
        href="/#work"
        className={cn}
        scroll={false}
        onClick={e => handleClick(e, '#work')}
      >
        Work
      </Link> */}
      <a
        href="https://instagram.com/capslock_mag"
        className={cn}
        target="_blank"
        aria-label="Instagram"
      >
        <span className="hidden md:inline">Instagram</span>
        <span className="inline md:hidden">IG</span>
      </a>
    </>
  )
}

export default Nav
